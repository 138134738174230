import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory, useLocation } from 'react-router-dom';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import EmailIcon from '@material-ui/icons/Email';
import MaterialMenu from './Menu';
import components from './plugins';

const useStyles = makeStyles(() => ({
    app: {
        height: '100vh',
        overflow: 'hidden'
    },
    logo: {
        margin: 'auto 0'
    },
    appContactGroup: {
        height: '40px',
        margin: '20px 0',
        display: 'flex',
        '& a': {
            color: 'white',
            padding: '0 8px',
            textDecoration: 'none'
        }
    },
    appContact: {
        display: 'flex'
    },
    appHeader: {
        height: '100px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        background: 'linear-gradient(to right,#57ad75 0%,#b8df67 100%)'
    },
    appNavigation: {
        height: '56px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 'auto 0'
    },
    appBody: {
        height: 'calc(100% - 100px)'
    },
    iframe: {
        border: 0,
        width: '100%',
        height: '100%'
    }
}));

function App({ plugins }) {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const currPath = (location.pathname || '/').split('/');
    const intendedPlugin = (currPath.slice(0, 2).join('/') || '/').toLowerCase();
    const restOfAddress = currPath.slice(2).join('/') + location.search + location.hash;
    //console.log(location);
    //console.log(currPath);
    //console.log(intendedPlugin);
    //console.log(restOfAddress);
    const load = () => {
        const { type, idx, entry } = (plugins.pluginsTypes[intendedPlugin] || {});
        if (!type) {
            return (<h1>Not Found</h1>);
        }
        switch (type) {
            case 'component':
                const C = components[`Component${idx}`];
                return (<C />);
            case 'iframe':
                return (<iframe src={`/plugins/bundle${idx}/${restOfAddress || entry || 'index.html'}`} title={intendedPlugin} className={classes.iframe} />);
            default:
                return (
                    <div>
                        <h1>Configuration Error</h1>
                        <h2>Plugin type: {type} Unknown</h2>
                    </div>
                );
        }
    };
    return (
        <div className={classes.app}>
            <AppBar position='static' className={classes.appHeader} role='banner'>
                <img className={classes.logo} width={165} height={41} alt='Anexsys Logo' src='/logo-A-white.png' />
                <nav className={classes.appNavigation} aria-label='Plugin Navigation' role='navigation'>
                    {
                        Object.keys(plugins.pluginsByCat).filter(cat => !!cat).map(cat => (<MaterialMenu key={cat} id={cat} label={cat} options={plugins.pluginsByCat[cat].map(p => ({
                            label: p.label,
                            callback: () => {
                                history.push(p.mountPoint)
                            }
                        }))} />))
                    }
                </nav>
                <div className={classes.appContactGroup}>
                    <a href='tel:+44 (0) 203 217 0300' aria-label='Call Anexsys'>
                        <div className={classes.appContact}>
                            <LocalPhoneIcon/>
                            <span>+44 (0) 203 217 0300</span>
                        </div>
                    </a>
                    <a href='mailto:hello@anexsys.com' aria-label='Email Anexsys'>
                        <div className={classes.appContact}>
                            <EmailIcon/>
                            <span>hello@anexsys.com</span>
                        </div>
                    </a>
                </div>
            </AppBar>
            <main className={classes.appBody} role='main'>
                {load()}
            </main>
        </div>
    );
}

export default App;
