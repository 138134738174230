import React from 'react';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import './plugin.css';

const useStyles = makeStyles(() => ({
    root: {
        marginLeft: 'auto',
        marginRight: 'auto',
        overflow: 'auto',
        width: '75%',
        height: '95%'
    },
    image: {
        backgroundSize: '100%',
        width: '100%',
        height: 0
    }
}));

function Component() {
    const classes = useStyles();
    return (
        <Paper elevation={12} className={classes.root}>
            <Box p={3}>
                <div className={clsx(classes.image, 'img-logo')} />
                <Typography variant="h1" align="center">Anexsys Dev Portal</Typography>
                <Typography variant="h4" align="center">Find documentation, guides, troubleshooting and FAQs for Anexsys Dev products.</Typography>
            </Box>
        </Paper>
    );
}

export default Component;
