import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Concepts from './Concepts';
import Features from './Features';
import Usage from './Usage';
import EntityViewer from './EntityViewer';
import Setup from './Setup';
import QuickStart from './QuickStart';
import logo from './img/Entity_WHT_Small.png';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: '95%',
        maxWidth: '95%',
        height: '95%',
        maxHeight: '95%',
        padding: '3px',
        margin: 'auto'
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    logo: {
        backgroundColor: '#3d4977'
    },
    tabPanel: {
        overflow: 'auto',
        paddingLeft: '15px'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`entity-tabpanel-${index}`}
            aria-labelledby={`entity-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `entity-tab-${index}`,
        'aria-controls': `entity-tabpanel-${index}`,
    };
}

function Component() {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const changeEntityTab = (event, newValue) => {
        setCurrentTab(newValue);
    };
    return (
        <Paper square className={`${classes.paper} ${classes.root}`}>
            <div>
                <img src={logo} alt="Entity Logo" className={classes.logo} />
                <Tabs
                    orientation="vertical"
                    variant="fullWidth"
                    indicatorColor="secondary"
                    textColor="secondary"
                    value={currentTab}
                    onChange={changeEntityTab}
                    aria-label="Entity tabs"
                    className={classes.tabs}
                >
                    <Tab label="Concepts" {...a11yProps(0)} />
                    <Tab label="Features" {...a11yProps(1)} />
                    <Tab label="Quick Start" {...a11yProps(2)} />
                    <Tab label="Usage" {...a11yProps(3)} />
                    <Tab label="Entity Viewer" {...a11yProps(4)} />
                    <Tab label="Setup" {...a11yProps(5)} />
                </Tabs>
            </div>
            <TabPanel value={currentTab} index={0} className={classes.tabPanel}>
                <Concepts />
            </TabPanel>
            <TabPanel value={currentTab} index={1} className={classes.tabPanel}>
                <Features />
            </TabPanel>
            <TabPanel value={currentTab} index={2} className={classes.tabPanel}>
                <QuickStart />
            </TabPanel>
            <TabPanel value={currentTab} index={3} className={classes.tabPanel}>
                <Usage />
            </TabPanel>
            <TabPanel value={currentTab} index={4} className={classes.tabPanel}>
                <EntityViewer />
            </TabPanel>
            <TabPanel value={currentTab} index={5} className={classes.tabPanel}>
                <Setup />
            </TabPanel>
        </Paper>
    );
}

export default Component;
