import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
//import images using local paths
import bundleReorder from './img/entity-quickstart_bundle-reorder.png';
import exportTemplates from './img/entity-quickstart_export-templates.png';
import exportRun from './img/entity-quickstart_export-run.png';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    image: {
        width: '100%'
    }
}));

function Step0({ classes }) {
    return (
        <List>
            <ListItem><ListItemText primary="Fresh Workspace?" secondary="Bulk load all the documents from the review workspace" /></ListItem>
            <ListItem><ListItemText primary="Part of Review Workspace?" secondary="No additional work required" /></ListItem>
        </List>
    );
}

function Step1({ classes }) {
    return (
        <div>
            <Typography variant='body1'>Create a new eBundle object</Typography>
            <Typography variant='body2'>The required information is:</Typography>
            <List>
                <ListItem><ListItemText primary="Name" secondary="The name that the eBundle will be referenced by" /></ListItem>
                <ListItem><ListItemText primary="Sort Search" secondary="The Relativivty search that will define the sort order for the bundle" /></ListItem>
            </List>
        </div>
    );
}

function Step2({ classes }) {
    return (
        <div>
            <Typography variant='body1'>Add the field "eBundle_eBundle Name" to a document layout</Typography>
            <Typography variant='body2'>This is a multi object field, and you can use it to associate a document with your newly created eBundle object</Typography>
            <Typography variant='caption'>Note: If you mass tag the documents, you will need to additionally order the bundle - this is handled if documents are added one by one</Typography>
            <img className={classes.image} src={bundleReorder} alt='Re-order Bundle'/>
            <Typography variant='caption'>To order a bundle, you will need to view the eBundle object, and click the "Order Bundle" console button on the right</Typography>
        </div>
    );
}

function Step3({ classes }) {
    return (
        <div>
            <Typography variant='body1'>Create a new eBundle Export object</Typography>
            <Typography variant='body2'>Please check the full field options in Usage (Create A Bundle Builder), but the following is a bare minimum to build a bundle</Typography>
            <img className={classes.image} src={exportTemplates} alt='Create Export from Template'/>
            <Typography variant='caption'>Use the Mode selection in the top left of the Custom Page to change to "eBundle Export" - this will allow you to pick a wizard to make a new export, with the minimum required fields</Typography>
            <img className={classes.image} src={exportRun} alt='Create Export from Template'/>
            <Typography variant='caption'>Once you have filled in the required fields and pressed Submit, it will create your export object. From here, you can use the second menu in the top right to export a bundle or index</Typography>
        </div>
    );
}

function getSteps() {
    return [ ['Create Workspace', Step0], ['Define Bundles', Step1], ['Tag Documents to Bundle(s)', Step2], ['Generate Indexes', Step3] ];
}

function Component() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <div className={classes.root}>
            <Typography variant="h2">Quick Start</Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map(([label, Component], idx) => (
                    <Step key={label}>
                        <StepLabel onClick={() => setActiveStep(idx)}>{label}</StepLabel>
                        <StepContent>
                            <Component classes={classes} />
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                    <Typography>All steps completed - you&apos;re finished</Typography>
                    <Button onClick={handleReset} className={classes.button}>
                        Reset
                    </Button>
                </Paper>
            )}
        </div>
    );
}

export default Component;
