import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import entityViewer from './img/entity-viewer.png';
import entityHeader from './img/entity-viewer-header.png';
import entitySearchTerms from './img/entity-viewer-terms.png';
import entityToolbar from './img/entity-viewer-toolbar.png';
import entityLeftPane from './img/entity-viewer-left-pane.png';
import entityRedactions from './img/entity-viewer-redactions.png';
import entityTooltip from './img/entity-viewer-tooltip.png';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left'
    },
    image: {
        maxWidth: '100%'
    }
}));

function Component() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h2">Entity Viewer</Typography>
            <p>
                The Entity viewer is a PDF viewer separate from the Relativity native viewer, which offers additional features to help with trial preparation.
            </p>
            <img className={classes.image} src={entityViewer} alt='Entity viewer'/>
            <Typography variant="body1">The Entity viewer can be used to view PDF documents. In Server Relativity, it can be set up as the default viewer for PDFs, whereas it is a radio button choice for Relativity 1</Typography>

            <Typography variant="h3">The Header Bar</Typography>
            <Typography variant="body1">The top bar of the Entity viewer makes some useful tools available to the user.</Typography>
            <img className={classes.image} src={entityHeader} alt='Entity header'/>

            <Typography variant="h4">1. Layers</Typography>
            <Typography variant="body1">In the middle of the bar there is a drop down for the layer to be used. This determines the layer on which document mark up is made. The values available here will depend on the pdfLayers assigned to the current Case User.</Typography>
            <Typography variant="h4">2. Search Terms</Typography>
            <Typography variant="body1">When clicking the first icon in the Search Term section, a new panel will appear. Here you can type terms and use the button to insert them for higlighting on the document.</Typography>
            <img className={classes.image} src={entitySearchTerms} alt='Entity Search Terms'/>
            <Typography variant="body1">The Whole Words switch will toggle on or off whether the highlighting only applies to entire words or not. Clicking it on would remove Appellant as a hit, in the above screenshot.</Typography>

            <Typography variant="h3">The Tool Bar</Typography>
            <Typography variant="body1">The second bar, directly below the header bar, of the Entity viewer exposes more tools for navigating and marking up the document.</Typography>
            <img className={classes.image} src={entityToolbar} alt='Entity toolbar'/>

            <Typography variant="h4">Left pane access</Typography>
            <Typography variant="body1">The first icon on the left offers you access to the left panel. A click on the icon will open or close the panel, and using the arrow will open a drop down to choose what to display in the panel</Typography>
            <img src={entityLeftPane} alt='Entity left pane'/>
            <Typography variant="body1">The choices to view are Thumbnails, Outline, Annotations and Bookmarks</Typography>

            <Typography variant="h4">Document Navigation and Display</Typography>
            <Typography variant="body1">The next set of icons allow for navigation and choosing how the document is displayed. You can use the page widget to jump between pages or to a given page by typing the number and pressing enter.</Typography>
            <Typography variant="body1">Clicking on the hand icon will change the cursor function, and allow you to click onto the  document and move it around with your mouse movements.</Typography>
            <Typography variant="body1">The final set  of icons pertain to the document zoom. The magnifying glass icons will zoom you in our out, and the Fit Page icon will jump between two presets. You can also zoom by holding  Ctrl, and using the mouse wheel.</Typography>

            <Typography variant="h4">Right hand context items</Typography>
            <Typography variant="body1">The icons on the right are dependent on the layer, and the users permissions on the layer.</Typography>
            <Typography variant="body1">By clicking the first icon in this section you will enter the annotation mode. This changes the options available on the toolbar, to the annotation tools, so that you can markup the document. This requires having write permissions.</Typography>
            <img className={classes.image} src={entityRedactions} alt='Entity redactions'/>
            <List>
                <ListItem><ListItemText primary="Freeform annotations" secondary="The first icon will engage the free hand annotation function. This will let you markup the document using clicks and moves with the mouse. Clicking the down arrow will allow you to choose different freeform annotations." /></ListItem>
                <ListItem><ListItemText primary="Stamping" secondary="The second icon will open a dialog which options for applying stamps to the document." /></ListItem>
                <ListItem><ListItemText primary="Notes" secondary="The third icon will allow the user to click on the document and apply a 'sticky note', where you can add text." /></ListItem>
                <ListItem><ListItemText primary="Shapes" secondary="The fourth icon will allow the user to add a shape. Again, clicking the downward arrow will allow the user to pick the required shape." /></ListItem>
                <ListItem><ListItemText primary="Comments" secondary="The final icon will allow you to add a comment to the document. This is similar to the note above, but will allow a conversation of messages instead. You can even use the '@' symbol to tag other Case Team Users and generate notifications for them." /></ListItem>
            </List>
            <Typography variant="body1">Clicking the icon on the left of the bar will leave the annotation mode.</Typography>
            <Typography variant="body1">The print icon will let the user print the document, if permissions allow.</Typography>
            <Typography variant="body1">The magnifying glass icon will allow the user to search the document. Typing a search word in here will highlight the term and let you jump between instances. This will take precdence over any search terms in the header bar.</Typography>
            <Typography variant="body1">If there are search terms in the header bar, you can jump between instances of them using the left and right arrows in the search pop up.</Typography>
            <Typography variant="body1">The eye icon toggles whether or not any hyperlinks in the document are visible.</Typography>
            <Typography variant="body1">The export button allows for saving the document. The different options allow you to either flattern the annotations into the saved document, or export without the annotations. This is permission dependent.</Typography>

            <Typography variant="h3">The Document</Typography>
            <Typography variant="body1">The rendered PDF can be used interactively. You can select text (assuming there is a text layer on the document) which will open up a tooltip to let you markup the text.</Typography>
            <img className={classes.image} src={entityTooltip} alt='Entity text tooltip'/>
            <Typography variant="body1">The first four icons on the tooltip will let you add styling to the selected text.</Typography>
            <Typography variant="body1">The fifth icon will generate a comment at the selected point. This is the same comment component as above.</Typography>
            <Typography variant="body1">The final icon will open a dialog box to allow the user to select another document in the workspace that you can create a hyperlink to.</Typography>
            

        </div>
    );
}

export default Component;
