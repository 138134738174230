import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left'
    }
}));

function Component() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h2">Entity Features</Typography>
            <p>
                The following actions typify using the building blocks within Entity to build bundles and work with the case team:
            </p>
            <List>
                <ListItem><ListItemText primary="Review Summaries and Notifications" secondary="See what has changed in the workspace and review and act on notifications directly from the Landing tab" /></ListItem>
                <ListItem><ListItemText primary="Add Existing Documents to Bundles" secondary="Tag documents to one or more eBundle objects for them to be included" /></ListItem>
                <ListItem><ListItemText primary="Upload Documents to a Bundle" secondary="Use the Uploader tab to drag and drop content straight into a bundle, including automatic hyperlinking" /></ListItem>
                <ListItem><ListItemText primary="Summarise Your Bundles" secondary="Run a Report Specification to generate a point in time snapshot of how a bundle looks" /></ListItem>
                <ListItem><ListItemText primary="Annotate and Comment on Documents" secondary="Use the Entity PDF Viewer to make comments within specific areas of documents and make private and public annotations" /></ListItem>
                <ListItem><ListItemText primary="Reorder the Index for a Bundle" secondary="From an eBundle Export object, apply the associated sort ordering to put documents in order within the bundle" /></ListItem>
                <ListItem><ListItemText primary="Generate a PDF Index for a Bundle" secondary="From an eBundle Export object, build a PDF file you can view directly which applies the index template to generate the table of contents and index pages" /></ListItem>
                <ListItem><ListItemText primary="Generate a Draft PDF Bundle" secondary="From an eBundle Export object, build a PDF you can view directly which is obviously a draft but represents what the finished bundle would look like" /></ListItem>
                <ListItem><ListItemText primary="Generate a PDF Bundle" secondary="From an eBundle Export object, build a PDF which represents the completed bundle" /></ListItem>
                <ListItem><ListItemText primary="Version a Bundle" secondary="Declare a version for a particular eBundle such that any future changes are performed as insertions" /></ListItem>
            </List>
            <p>There are other actions available, but these are the primary use cases</p>
        </div>
    );
}

export default Component;
