/**
 * Initially copied from https://mui-treasury.com/styles/menu/ on 14/10/2020 12:48 BST by AH
 */
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

// Original design here: https://github.com/siriwatknp/mui-treasury/issues/777

const minWidth = 140;

const useStyles = makeStyles(() => ({
    button: {
        minWidth: minWidth,
        background: 'white',
        fontWeight: 500,
        textTransform: 'capitalize',
        borderColor: green[500],
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: 4,
        paddingTop: 8,
        paddingBottom: 8,
        "& > span": {
            fontSize: '0.9em'
        }
    },
    catIcon: {
        color: green[400],
        userSelect: 'none',
        pointerEvents: 'none',
        marginRight: 8
    },
    paper: {
        minWidth: minWidth,
        borderRadius: 4,
        marginTop: 8
    }
}));

const MaterialMenu = ({ id, label, options }) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option) => {
        setAnchorEl(null);
        option && option.callback();
    };

    id = id || `${Date.now()}_${Math.random()}`;

    return (
        <div>
            <Button
                className={classes.button}
                aria-controls={`cat-menu-${id}`}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {anchorEl ? <MenuOpenRoundedIcon className={classes.catIcon} /> : <MenuRoundedIcon className={classes.catIcon} />}
                <span>{label}</span>
            </Button>
            <Menu
                id={`cat-menu-${id}`}
                classes={{ paper: classes.paper }}
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                {options.map((option, idx) => (<MenuItem key={`${id}_${idx}`} onClick={() => handleClose(option)}>{option.label}</MenuItem>))}
            </Menu>
        </div>
    );
};

export default MaterialMenu;
