import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left'
    }
}));

function Component() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h2">Entity Concepts</Typography>
            <p>
                Entity is a trial preparation and presentation tool. It can be deployed into a Relativity workspace to provide seamless bundling features that can operate with Relativity workflow.
            </p>
            <p>
                Entity, when within Relativity, uses Relativity objects to represent the data required to maintain state. The following objects define how Entity works:
            </p>
            <List>
                <ListItem><ListItemText primary="eBundle" secondary="Defines a group that describes related documents, e.g. 'A - Witness Statements'" /></ListItem>
                <ListItem><ListItemText primary="eBundle Position" secondary="Define whereabouts in an eBundle a document should be, given some sorting or ordering" /></ListItem>
                <ListItem><ListItemText primary="eBundle Export" secondary="Define the shape of a generated bundle and how the combined documents should be indexed and numbered, given one or more eBundles" /></ListItem>
                <ListItem><ListItemText primary="eBundle Export File" secondary="An actual generated bundle file (can be many per eBundle Export with drafts, versions and insertions)" /></ListItem>
                <ListItem><ListItemText primary="Report Specification" secondary="Defines a summary that can be run at any time to build a snapshot of the bundles" /></ListItem>
                <ListItem><ListItemText primary="Report Result" secondary="An actual snapshot built by applying a Report Specification and saved against the spec to record the state at that point in time" /></ListItem>
                <ListItem><ListItemText primary="Hyperlink" secondary="Defines how two documents are connected, such as a reference in a witness statement" /></ListItem>
                <ListItem><ListItemText primary="PDF Viewer Access" secondary="Defines the permissions on a layer which allows control of annotations within a document" /></ListItem>
                <ListItem><ListItemText primary="Case Team" secondary="Defines a person associated with the case, which may have a Relativity User assigned, but may just have an email address, such as a witness" /></ListItem>
                <ListItem><ListItemText primary="Notification" secondary="Represents data intended to be seen by a Case Team person or group of Case Team persons, such as a comment in a document or a summary of document changes" /></ListItem>
                <ListItem><ListItemText primary="Case Management Timeline" secondary="Represents the critical path and dependencies between actions to keep the case running smoothly" /></ListItem>
            </List>
            <p>There are other objects and fields used internally, but these are the primary ones a user should expect to work with. The only objects a user has to work with directly, is the eBundle object and documents - everything else can be interacted with via our UI</p>
        </div>
    );
}

export default Component;
