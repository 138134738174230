import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left'
    },
    image: {
        maxWidth: '100%'
    }
}));

function Component() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h2">Entity Setup</Typography>
            <Typography variant="h4">Setting up the Entity Config</Typography>
            <Typography variant="body1">There is an Entity Config object that will allow the administrator to set some values for advanced Entity behaviour.</Typography>
            <List>
                <ListItem><ListItemText primary="Name" secondary="The Relativity name of the config object" /></ListItem>
                <ListItem><ListItemText primary="Store PDFs" secondary="A Yes/No field to choose whether or not to store a PDF version of a document on a Relativity save of that document" /></ListItem>
                <ListItem><ListItemText primary="PDF S3 Bucket" secondary="This it the bucket in S3 where the documents would be saved to if the above Store PDFs is set to yes." /></ListItem>
                <ListItem><ListItemText primary="PSPDFKit Server URL" secondary="If using the Entity viewer, you will need to have the server URL here, for collecting annotated versions during export." /></ListItem>
                <ListItem><ListItemText primary="ExternalRelativityAddress" secondary="If this is set, this is the route that the application will use to access Relativity." /></ListItem>
            </List>

            <Typography variant="h4">Setting up the Entity Viewer</Typography>
            <Typography variant="body1">An administrator will need to add some information to the RTK.Admin store for the application to function properly. This will include make a REST call to store information on a per workspace basis.</Typography>
            <Typography variant="body1">There is documentation on how to make these calls elsewhere in the documentation site. The information required for this app (RTK.TrialViewer) is:</Typography>
            <List>
                <ListItem><ListItemText primary="jwtService" secondary="The URL for collecting tokens to access data on the PSPDFKit server" /></ListItem>
                <ListItem><ListItemText primary="pspApiPassword" secondary="The API key to allow access to the PSPSDFKit server" /></ListItem>
                <ListItem><ListItemText primary="serverUrl" secondary="This is the URL for the PSPDFKit server, where we access the documents. This will be the same as in the PSPDFKit Server URL field in the Entity Config." /></ListItem>
                <ListItem><ListItemText primary="useServer" secondary="This should be set to true, so that the application uses the server." /></ListItem>
            </List>


        </div>
    );
}

export default Component;
