import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import './usage.css';

import bundleNew from './img/entity-usage_bundle-new.png';

const useStyles = makeStyles(theme => ({
    root: {
        textAlign: 'left'
    },
    // commented out styles used when usage tabs are vertical (i.e. wrap the <AppBar><TabPanels>... with a <div className={classes.tabRoot}>)
    /*
    tabRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        padding: '3px',
        margin: 'auto'
    },
    */
    /*
    tabPanels: {
        offset: theme.mixins.toolbar
    },
    */
    /*
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    */
    tabWrap: {
        width: '100%',
        backgroundColor: theme.palette.background.paper
    },
    img: {
        width: '100%'
    },
    image: {
        backgroundSize: '100%',
        width: '100%',
        height: 0
    },
    title: {
        paddingTop: '10px',
        marginBottom: '10px'
    },
    tabPanel: {
        overflow: 'auto'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`entity-usage-tabpanel-${index}`}
            aria-labelledby={`entity-usage-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `entity-usage-tab-${index}`,
        'aria-controls': `entity-usage-tabpanel-${index}`,
    };
}

function Tab0({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Create a Bundle</Typography>
            <div className={clsx(classes.image, 'img-bundle-new')} />
            <List>
                <ListItem><ListItemText primary="Name Field" secondary="This is the name of the bundle, e.g. 'A'" /></ListItem>
                <ListItem><ListItemText primary="Sort Search Field" secondary="This is the search which is used to define the sort columns for automated ordering for documents within the bundle; the search results themselves are not used" /></ListItem>
                <ListItem><ListItemText primary="Start Number Field" secondary="Provide optional approximate page number offset (e.g. if you expect the index to be 3 pages long, put 4 here to give rough numbering estimates)" /></ListItem>
                <ListItem><ListItemText primary="Page Number Prefix and Suffix Fields" secondary="Provide optional strings which will be placed verbatim around the page number value when the bundle PDF is built" /></ListItem>
                <ListItem><ListItemText primary="Manual Mode Field" secondary="Whether or not the bundle should be managed manually from the outset; this field will be set later (automatically) if a user decides to take control of ordering from the bundle viewer" /></ListItem>
            </List>
        </>
    );
}

function Tab1({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Create a Bundle Builder</Typography>
            <img className={classes.img}  src={bundleNew} alt='New eBundle' />
            <Typography variant="body1" >A eBundle Export object can be created either by using the Relativity Object or the eBundle Viewer Custom Page</Typography>
            <Typography variant="body2" >The easiest option is to use one of the wizards in the Custom Page, as they only present the relevant fields to fill in. Here we explain all the fields, via the Relativity Object Interface for completeness</Typography>
            <List>
                <ListItem><ListItemText primary="New eBundle Export from Template" secondary="This is the easiest wizard to use, assuming you start with an index template - RECOMMENDED" /></ListItem>
                <ListItem><ListItemText primary="New eBundle Export from Existing Index" secondary="This will give you the same information as above, but the extra settings with regards to how to fill in the existing index" /></ListItem>
                <ListItem><ListItemText primary="New eBundle Export from Text Placeholder" secondary="This is a small set of fields for creating a basic bundle without an index template - it relies on text being given in the editor for a cover page" /></ListItem>
                <ListItem><ListItemText primary="New eBundle Export - Advanced" secondary="This returns all choices available for the export creation. Once an export has been created, it will be shown with this set of fields" /></ListItem>
            </List>
            <List>
                <ListSubheader>General</ListSubheader>
                <ListItem><ListItemText primary="Export Name" secondary="The name of the Relativity Export object" /></ListItem>
                <ListItem><ListItemText primary="eBundles to Export" secondary="A multi object field of the eBundle objects to be included in the export" /></ListItem>
                <ListItem><ListItemText primary="Combine PDFs" secondary="A single choice field to determine the output format of the export" /></ListItem>
                <ListSubheader>Build Index</ListSubheader>
                <ListItem><ListItemText primary="Template or Existing File" secondary="A file field to upload either a template file or a filled in index to use for the bundle" /></ListItem>
                <ListItem><ListItemText primary="IndexTemplateText" secondary="If not using a template, you can write text for the index header here" /></ListItem>
                <ListItem><ListItemText primary="Bundle Name" secondary="This is any text that should replace a `{{name}}` template in the index, if the template exists" /></ListItem>
                <ListItem><ListItemText primary="Project Reference" secondary="This is any text that should replace a `{{project}}` template in the index, if the template exists" /></ListItem>
                <ListItem><ListItemText primary="Creator" secondary="This is any text that should replace a `{{creator}}` template in the index, if the template exists" /></ListItem>
                <ListItem><ListItemText primary="TableReplacementTag" secondary="The name of the template on the index to insert the index table into - typically {{table}}" /></ListItem>
                <ListItem><ListItemText primary="Loadfile Type (For Zip Exports)" secondary="A single choice field to choose the type of export loadfile (when exporting to zip in the Combine PDFs field only)" /></ListItem>
                <ListItem><ListItemText primary="Notes" secondary="This is any text that should replace a `{{notes}}` template in the index, if the template exists" /></ListItem>
                <ListItem><ListItemText primary="RepeatHeadersPerPage" secondary="Select this to have the column headers at the top of each page" /></ListItem>
                <ListItem><ListItemText primary="ColumnWidthPercentages" secondary="This is a field to input a JSON array to represent the column widths of the index. For an index with 4 columns you might input `[10.0,50.0,30.0,10.0]`" /></ListItem>
                <ListItem><ListItemText primary="GroupingRows" secondary="DEPRECATED: This is left in to maintain support for existing bundles. Separator objects should be used in the UI now. This is a field to input a JSON object to determine where to add sub headers. `{'1':'Introduction'}` would add a subheader of 'Introduction' ahead of the document with Tab 1. Soon to be deprecated to use a separator object" /></ListItem>
                <ListItem><ListItemText primary="IndexTableStyling" secondary="This is a field to input a JSON object to determine the styling of the index. Use the Custom Page for an object template to fill in for ease of use" /></ListItem>
                <ListItem><ListItemText primary="IndexReplacements" secondary="This is a field to input a JSON object to determine any replacements to be made on the Index Template. `{'{{insertHere}}': 'Inserted Value'}` would add 'Inserted Value' onto the index in place of the '{{insertHere}}' template" /></ListItem>
                <ListItem><ListItemText primary="IndexFields" secondary="DEPRECATED: Please use BundleIndexFieldList now. A multi choice field to choose the fields that appear in the index. UseSortSearchFields will include all fields in the Sort Search picked for the bundle, in the order they are in" /></ListItem>
                <ListItem><ListItemText primary="BundleIndexFieldsCustom" secondary="This should be a JSON array of field names to insert into the index, e.g. `['Title', 'Date']` to insert fields with those names. The names must match the Relativity field names" /></ListItem>
                <ListItem><ListItemText primary="Index Fields Order" secondary="DEPRECATED: Please use BundleIndexFieldList now. This is a field to input a JSON object to determine the order of the fields in the index. The JSON property should match the choice name in the Index Fields field, and the value you should be numbers to determine the order, e.g. `{'UseTabNumber':1, 'UseStarPage':2}`" /></ListItem>
                <ListItem><ListItemText primary="BundleIndexFieldList" secondary="This field determines the fields to use in the index, and the order of them too. It should be set using the custom page UI. It replaces the combination of IndexFields and Index Fields Order." /></ListItem>
                <ListItem><ListItemText primary="IndexFieldNameMappings" secondary="This is a field to input a JSON object to determine the column header for fields included from the Sort Search. `{'Control Number': 'Document'}` would rename the column for the 'Control Number' to 'Document'" /></ListItem>
                <ListItem><ListItemText primary="ColumnHeaderOverrides" secondary="This is the same as IndexFieldNameMappings, except for the built in columns. `{'UseTabNumber': 'Tab'}` would rename the column for the Tab column to 'Tab'" /></ListItem>
                <ListSubheader>Build From Existing Index</ListSubheader>
                <ListItem><ListItemText primary="Given Index File is Wholly or Partially Complete" secondary="Select this if the uploaded index is not a template, but has a table of documents" /></ListItem>
                <ListItem><ListItemText primary="ID Column in Given Index" secondary="This should be the column number of the ID column, in the pre filled index table" /></ListItem>
                <ListSubheader>Pagination</ListSubheader>
                <ListItem><ListItemText primary="Apply Pagination" secondary="Tick this box to apply pagination to the built bundle" /></ListItem>
                <ListItem><ListItemText primary="First Page Number" secondary="Entering a number will start the pagination at that number rather than from 1" /></ListItem>
                <ListItem><ListItemText primary="Page Number Prefix" secondary="Enter a string to append to the start of every document's page number" /></ListItem>
                <ListItem><ListItemText primary="Page Number Suffix" secondary="Enter a string to append to the end of every document's page number" /></ListItem>
                <ListItem><ListItemText primary="PaginationSelectOptions" secondary="Choose whether or not to paginate the index or not. Changing this will have no effect after running for the first time on a versioned bundle - you will need to create a new export if it needs to be changed" /></ListItem>
                <ListItem><ListItemText primary="ShouldUpdatePageLabels" secondary="Ticking this will update the page numbers to represent the given page value (will respect insertions) - this is only relevant when viewing the PDF in Adobe" /></ListItem>
                <ListItem><ListItemText primary="Pagination Template" secondary="This field will let you create a template for the pagination - it will override the Page Number Prefix/Suffix fields. See information at the bottom of this page on templates" /></ListItem>
                <ListItem><ListItemText primary="Pagination Styling" secondary="This is a field to input a JSON object to determine the styling of the pagination. Use the Custom Page for an object template to fill in for ease of use" /></ListItem>
                <ListItem><ListItemText primary="MultiStamp" secondary="This is a field to input a JSON array to determine any extra stamps to apply to the document. Use the Custom Page for an object template to fill in for ease of use" /></ListItem>
                <ListSubheader>Bookmarking</ListSubheader>
                <ListItem><ListItemText primary="ApplyBookmarking" secondary="Tick this box to apply bookmarks to the built bundle" /></ListItem>
                <ListItem><ListItemText primary="Field to use as Bookmark Value" secondary="Choose a field that you want to have as the Bookmark value" /></ListItem>
                <ListItem><ListItemText primary="BookmarkTemplate" secondary="This field will let you create a template for the bookmark - it will override the above field. See information at the bottom of this page on templates" /></ListItem>
                <ListSubheader>Watermarking</ListSubheader>
                <ListItem><ListItemText primary="This is being deprecated, and the MultiStamp field in Pagination should be used instead" /></ListItem>
                <ListSubheader>Index Linking</ListSubheader>
                <ListItem><ListItemText primary="Hyperlinking" secondary="Use this field to choose which columns (if any) are hyperlinked in the index" /></ListItem>
                <ListItem><ListItemText primary="ShouldCountSmallRows" secondary="This is used when building from an existing index. If selected, it will try and hyperlink any row which has fewer columns than expected" /></ListItem>
                <ListItem><ListItemText primary="Header Rows in Index Table" secondary="This is used when building from an existing index. This specifies the number of header rows at the top of each table to count as header rows (and not hyperlink)" /></ListItem>
                <ListItem><ListItemText primary="Page Number Column in Given Index" secondary="This is used when building from an existing index. Specify the column that the page number should go in, and the linker can update it for the correct page value" /></ListItem>
                <ListSubheader>Other Document Options</ListSubheader>
                <ListItem><ListItemText primary="Override Options" secondary="This field will let you select extra capabilities for the export which are hopefully self explanatory" /></ListItem>
                <ListItem><ListItemText primary="ProductionSetForContent" secondary="DEPRECATED: The preference is to use the ProductionSetList field now. Here you can choose a production set - if Production Images are chosen in the PDF Provider list the images will come from this production, where applicable" /></ListItem>
                <ListItem><ListItemText primary="ProductionSetList" secondary="This field determines the Production Set images to use when collecting PDFs, and the order of precedence for collecting them. This requires Production Images to be collected in the PDF Provider Options" /></ListItem>
                <ListItem><ListItemText primary="DuplicatePointer" secondary="This is relevant for exporting documents as individual files. Using this field will only export one copy of a document based on this field value, and link other instances to the single copy" /></ListItem>
                <ListItem><ListItemText primary="PageOrientation" secondary="This will let you choose which orientation to try and change documents to. There is a known issue here, where documents have been rotated before being uploaded to Relativity, they can be rotated further to the wrong orientation" /></ListItem>
                <ListItem><ListItemText primary="IncludeNativePointer" secondary="This is relevant for exporting documents as individual files. This should be the name to use for the exported native file" /></ListItem>
                <ListItem><ListItemText primary="RotateClockwise" secondary="If PageOrientation is set, this will force the rotation to be clockwise - otherwise it will be anti-clockwise" /></ListItem>
                <ListItem><ListItemText primary="PDF Layer viewer access" secondary="This will export the annotations on the PSPDFKit layer selected, if there are any, and the PDF Provider list uses PSPDFKit" /></ListItem>
                <ListItem><ListItemText primary="RunOCROnBundle" secondary="Ticking this will cause the built bundle to be OCRed - this OCRs all documents and will slow the process down significantly" /></ListItem>
                <ListItem><ListItemText primary="VolSize" secondary="This is relevant for exporting documents as individual files. This will set the max number of documents that are exported into a single folder" /></ListItem>
                <ListItem><ListItemText primary="PDF Provider Options" secondary="This needs to be a JSON array to choose the order of PDF Provider for the documents in the Bundle. The options are: 'PSPDFKit', 'S3 PDF', 'Relativity', 'Relativity DB', 'Entity Converter', 'Relativity Images' and 'Relativity Production Images'" /></ListItem>
            </List>
            <Typography variant="body1" >Templates</Typography>
            <Typography variant="body2" >Many of the above fields take a template value. That is a piece of text, that uses curly braces to add document specific values into the template</Typography>
            <Typography variant="body2" >The available values are:</Typography>
            <List>
                <ListItem><ListItemText primary="id" secondary="The tab number of the document" /></ListItem>
                <ListItem><ListItemText primary="pagination" secondary="This is only available for pagination templates, as it increments with the pages. In other circumstances you can use startpage, endpage or pagerange" /></ListItem>
                <ListItem><ListItemText primary="pagestart" secondary="The starting page of the document" /></ListItem>
                <ListItem><ListItemText primary="pageend" secondary="The end page of the document" /></ListItem>
                <ListItem><ListItemText primary="pagerange" secondary="The start page joined to the end page with a hyphen - this is a shortcut for a template that looks like `{pagestart}-{pageend}`" /></ListItem>
                <ListItem><ListItemText primary="bookmark" secondary="This will output the same value as selected for the bookmark field." /></ListItem>
            </List>
            <Typography variant="body2" >{"For example, a bookmark template of `{id}-{pagestart}` would render `1-3` for a document at tab 1 that starts on page 3"}</Typography>
        </>
    );
}

function Tab2({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Create a Case User</Typography>
            <div className={clsx(classes.image, 'img-case-user-new')} />
            <List>
                <ListItem><ListItemText primary="Name" secondary="The name of the user" /></ListItem>
                <ListItem><ListItemText primary="Relativity User" secondary="This is the Relativity user to be associated with the Case Team User. The Relativity user will need case permissions (not just System Administrator)" /></ListItem>
                <ListItem><ListItemText primary="Email" secondary="The email of the user. This can be used to send notifications to the user" /></ListItem>
                <ListItem><ListItemText primary="Contact Number" secondary="The phone number of the user. This is just a convenience, but serves no purpose in Relativity" /></ListItem>
                <ListItem><ListItemText primary="Role" secondary="The role of the user. This is just for reference, and we don't use this value" /></ListItem>
                <ListItem><ListItemText primary="Responsible For" secondary="A multi object field to Case Management Timeline objects - can help lay out a structure/timeline for the case, but not used by the application" /></ListItem>
                <ListItem><ListItemText primary="CaseTeamGroup" secondary="A link to another Case Team User, which also applies their permissions to the user" /></ListItem>
                <ListItem><ListItemText primary="PdfLayers" secondary="A multi object field to PdfViewerAccess objects. These objects dictate the Layer, and the permissions applied to that layer" /></ListItem>
            </List>
        </>
    );
}

function Tab3({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Create a PDF Layer</Typography>
            <div className={clsx(classes.image, 'img-pdf-layer-new')} />
            <List>
                <ListItem><ListItemText primary="Name Field" secondary="Name to identify the combination of layer and permissions, e.g. 'Export Read and Write'" /></ListItem>
                <ListItem><ListItemText primary="Layer Field" secondary="Select or add the Layer in question; the distinct set of layers appear in the dropdown at the top of the Entity PDF Viewer" /></ListItem>
                <ListItem><ListItemText primary="Viewer Permissions Field" secondary="The permissions string to apply to the layer, please see below." /></ListItem>
            </List>
            <Typography variant="h5" className={classes.title}>PDF Layer Permissions</Typography>
            <p>PDF layer permissions are used as JSON to comply with the Viewer expectations, and can be the string <code>"all"</code> to allow for all Viewer options or a JSON array of strings, e.g. <code>[ "value", "value2" ]</code>, where the valid options are as follows:</p>
            <List>
                <ListItem><ListItemText primary="read-document" secondary="Layer can be opened and annotations visible but cannot edit or add any annotations" /></ListItem>
                <ListItem><ListItemText primary="write" secondary="Annotations can be added to layer" /></ListItem>
                <ListItem><ListItemText primary="download" secondary="Can retrieve PDF of document with layer annotations included" /></ListItem>
            </List>
        </>
    );
}

function Tab4({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Upload Content</Typography>
            <div className={clsx(classes.image, 'img-uploader-config')} />
            <Typography variant="body1" >The Uploader can be used to upload new documents into the workspace.</Typography>
            <Typography variant="body2" >Clicking the menu button on the top right will open a set of fields to complete, including the folder to load into, the control number prefix, padding and start number.</Typography>
            <Typography variant="body2" >Once you have dragged documents onto the loading pad, you have the option mark any of them as an index. If you do this, they will be parsed, and the uploader will look for any hyperlinks it can make within the new document, based on Control Numbers</Typography>
            <Typography variant="body2" >Pressing either upload button will commence the upload of documents.</Typography>
        </>
    );
}

function Tab5({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Check Overall State</Typography>
            <div className={clsx(classes.image, 'img-landing')} />
            <Typography variant="body1" >The Landing page will give you a brief update on activity in the workspace.</Typography>
            <Typography variant="body2" >The top section gives a summary on the activity generally. This requires the agent to be enabled.</Typography>
            <Typography variant="body2" >The lower section offers the notifications for the logged in user. These can be generated using the Entity viewer.</Typography>
        </>
    );
}

function Tab6({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Review Case Management</Typography>
            <div className={clsx(classes.image, 'img-case-man')} />
            <p>
                Review your critical path and dependencies.
            </p>
        </>
    );
}

function Tab7({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Create a Report Spec</Typography>
            <div className={clsx(classes.image, 'img-report-spec-new')} />
            <List>
                <ListItem><ListItemText primary="Name Field" secondary="Name of Report Spec, e.g. Duplicates Report" /></ListItem>
                <ListItem><ListItemText primary="Category Fields" secondary="The valid possible document properties to be summarised. When reporting on duplicates, provide a field to use to identify duplicates." /></ListItem>
            </List>
        </>
    );
}

function Tab8({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>Run and View a Report</Typography>
            <div className={clsx(classes.image, 'img-report')} />
            <p>
                Once triggered and complete, reports are attached to their spec for viewing within Relativity
            </p>
        </>
    );
}

function Tab9({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>List Notifications</Typography>
            <div className={clsx(classes.image, 'img-notifs-objects')} />
            <p>
                Notification objects wrap up all integrations between Entity processes.
                This is typically internal to Entity but it is useful for an administrative user to review them.
                An end user will typically use the Landing page to acces their current notifications.
            </p>
        </>
    );
}

function Tab10({ classes }) {
    return (
        <>
            <Typography variant="h4" className={classes.title}>List Hyperlinks</Typography>
            <div className={clsx(classes.image, 'img-hyperlink-objects')} />
            <p>
                Hyperlinks and Hyperlink Sets record the links between documents.
                This is typically internal to Entity but the links will be included in the final bundle so anything incorrect will proliferate and therefore should be reviewed within generated bundles.
            </p>
        </>
    );
}

function Component() {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const changeEntityUsageTab = (event, newValue) => {
        setCurrentTab(newValue);
    };
    return (
        <div className={clsx(classes.root, classes.tabWrap)}>
            <AppBar position="sticky" color="default">
                <Tabs
                    //orientation="vertical"
                    //variant="fullWidth"
                    variant="scrollable"
                    scrollButtons="auto"
                    indicatorColor="secondary"
                    textColor="secondary"
                    value={currentTab}
                    onChange={changeEntityUsageTab}
                    aria-label="Entity Usage tabs"
                    //className={classes.tabs}
                >
                    <Tab wrapped label="Create a Bundle" {...a11yProps(0)} />
                    <Tab wrapped label="Create a Bundle Builder" {...a11yProps(1)} />
                    <Tab wrapped label="Create a Case User" {...a11yProps(2)} />
                    <Tab wrapped label="Create a PDF Layer" {...a11yProps(3)} />
                    <Tab wrapped label="Upload Content" {...a11yProps(4)} />
                    <Tab wrapped label="Check Overall State" {...a11yProps(5)} />
                    <Tab wrapped label="Review Case Management" {...a11yProps(6)} />
                    <Tab wrapped label="Create a Report Spec" {...a11yProps(7)} />
                    <Tab wrapped label="Run and View a Report" {...a11yProps(8)} />
                    <Tab wrapped label="List Notifications" {...a11yProps(9)} />
                    <Tab wrapped label="List Hyperlinks" {...a11yProps(10)} />
                </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0} className={classes.tabPanel}>
                <Tab0 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={1} className={classes.tabPanel}>
                <Tab1 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={2} className={classes.tabPanel}>
                <Tab2 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={3} className={classes.tabPanel}>
                <Tab3 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={4} className={classes.tabPanel}>
                <Tab4 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={5} className={classes.tabPanel}>
                <Tab5 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={6} className={classes.tabPanel}>
                <Tab6 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={7} className={classes.tabPanel}>
                <Tab7 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={8} className={classes.tabPanel}>
                <Tab8 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={9} className={classes.tabPanel}>
                <Tab9 classes={classes} />
            </TabPanel>
            <TabPanel value={currentTab} index={10} className={classes.tabPanel}>
                <Tab10 classes={classes} />
            </TabPanel>
        </div>
    );
}

export default Component;
